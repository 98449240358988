import { openSans, signikaNegative, signika, inter } from '@/styles/fonts';
import { isNavigatorOnly } from '@/helpers/utils/publication';
import fontMappings from '@/ts/types/fonts';

const font = (publication: string | undefined) =>
  (
    (publication && fontMappings[publication] && fontMappings[publication].font) ||
    (isNavigatorOnly(publication) ? inter : openSans)
  ).className;

const publicationFontObject = (publication: string | undefined) =>
  (publication && fontMappings[publication] && fontMappings[publication].publicationFont) ||
  (isNavigatorOnly(publication) ? inter : signikaNegative);

const publicationFont = (publication: string | undefined) => publicationFontObject(publication).className;

const getAffiliateContentHeaderFont = () => signika.className;

const getAffiliateStickyContentFont = () => signikaNegative.className;

export default font;

export { publicationFont, publicationFontObject, getAffiliateContentHeaderFont, getAffiliateStickyContentFont };
